import React from "react"
import { navigate } from "gatsby"
import icons from "../assets/SVG/chevron-right.svg";
import "../styles/main.scss"

const Index = () => {
  return (
    <div className="Index-wrapper">
      <div className="Index-heroImage" />
      <div className="Index-content">
        <section className="Index-title">
          <h2 className="Index-name">ADRIENNE BORIS</h2>
          <h3 className="Index-title">Stage Director <br/>Acting Coach <br/> Producer</h3>
        </section>
        <section className="Index-blerb">
          Directing is about creating new worlds, both onstage &amp;
          in the rehearsal room.
        </section>
        <div className="Index-buttonWrap" onClick={() => navigate("/about")}>
          <svg className="Index-CTA">
          <use xlinkHref={`#${icons.id}`}/>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Index
